import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Topbar from '@semper/Topbar';
import {Link} from "react-router-dom";
import Logo from '@images/logo_white_with_label.svg';
import { apiRequest } from '@services/Request';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { helperValidator } from '@helpers/validator';
import './index.css'



class ForgotPassword extends React.Component{

    state = {
        isLoading: false,
        form: {
            email: '',
        },
        formValidation: {
            email: 'email|required',
        },
        formErrors:{},
        hasBeedSend: false,
        error: ''
    }
    onChange = (e) => {
        var form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        })
    }

    handleClose = () => {
        this.setState({
            error: ''
        })
    }

    forgotClick = () => {
        const { dispatch } = this.props;
        let errors = helperValidator.validate(this.state.form, this.state.formValidation);
        this.setState({
            formErrors: {}
        })
        if (Object.keys(errors).length) {
            this.setState({
                formErrors: errors
            })
            return false;
        }
        this.setState({
            isLoading:true
        })
        apiRequest('forgot-password', 'POST', this.state.form).then(request => {
            if (request.status === 200) {
                request.json().then(data => {
                    this.setState({
                        hasBeedSend: true
                    })
                })

            }
            else{
                if (request.status === 401) {
                    this.setState({error: 'Incorrect email or password'})
                }
                else if (request.status === 422) {
                    request.json().then(data => {
                        this.setState({
                            error: data.messages.join("\n")
                        })
                    })
                }
            }

            this.setState({
                isLoading:false
            })
        });
    }

    render() {
        const { hasBeedSend, form, error, isLoading, formErrors } = this.state;
        return (
            <div className="fullscreen bg-main-color">
                {hasBeedSend && <>
                    <Grid className="fullscreen" justify="center" container alignItems="center" spacing={1}>
                        <Grid item xs={11} spacing={1}>
                            <Grid justify="center" container alignItems="center" spacing={1}>
                                <Grid item xs={12} align="center" spacing={1}>
                                    <p>Check your mail</p>
                                    <br/><br/>
                                    <img src={Logo} alt="Semper"/>
                                    <br/><br/>
                                    <p>Restore link has been sent to your email</p>
                                    <br/><br/>
                                    <Link to="/login">Back to login</Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
                {!hasBeedSend && <>
                    <Topbar
                        className="topbar-forgot-pwd"
                        white={false}
                        title="Forgotten Password"
                    ></Topbar>
                    <Grid className="fullscreen" justify="center" container alignItems="center" spacing={1}>
                        <Grid item xs={11} spacing={1}>
                            <Grid justify="center" container alignItems="center" spacing={1}>
                                <Grid item xs={12} align="center" spacing={1}>
                                    <img src={Logo} alt="Semper" style={{marginTop: -55}}/>
                                    <p>Enter email you signed up to reset password</p>
                                </Grid>
                                <Grid item xs={12} spacing={1}>
                                    <TextField
                                        placeholder="Email"
                                        variant="outlined"
                                        name="email"
                                        onChange={this.onChange}
                                        value={form.email}
                                        fullWidth
                                        error={formErrors['email'] !== undefined}
                                        helperText={formErrors['email'] !== undefined ? formErrors['email'] : ''}
                                    />
                                    <br/><br/>
                                    <div onClick={this.forgotClick} style={{textAlign: "center"}}><Button disabled={isLoading} variant="contained">
                                        {!isLoading && <>Restore password</>}
                                        {isLoading && <CircularProgress size={29}  />}
                                    </Button></div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Snackbar open={!!error}  autoHideDuration={7000} onClose={this.handleClose}>
                        <Alert severity="error">
                            {error}
                        </Alert>
                    </Snackbar>
                </>}

            </div>
        )
    }
}


export default ForgotPassword;
