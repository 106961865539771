import React from 'react';
import Grid from '@material-ui/core/Grid';
import Logo from '@images/logo_white_with_label.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Link} from "react-router-dom";
import { apiRequest } from '@services/Request';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Subscriptions from '@semper/Subscriptions';
import { userActions } from '@actions/index';
import queryString from 'query-string';
import PhoneInput from 'react-phone-input-2'
import { helperValidator } from '@helpers/validator';
import 'react-phone-input-2/lib/style.css'
import './register.css'
import TelephoneInput from "@semper/TelephoneInput";

class Register extends React.Component{

    state = {
        registred: false,
        isLoading: false,
        wasInvated: false,
        alertMessageWasSend: false,
        subscriptionStep: false,
        confirmation_hash: '',
        cities: [],
        premium_products: [],
        user: {},
        access_token: '',
        waiting_list_cities: [],
        token: '',
        invitation: {},
        form: {
            firstname: '',
            lastname: '',
            email: '',
            phone_number: '',
            instagram_name: '',
            password: '',
            password_confirmation: '',
        },
        formValidation: {
            firstname: 'required',
            lastname: 'required',
            email: 'email|required',
            password: 'min:5|required',
            password_confirmation: 'min:5|required|password_confirmation',
            phone_number: 'min:5|required',
        },
        formErrors:{},
        error: ''
    }

    handleClose = () => {
        this.setState({
            error: ''
        })
    }

    componentDidMount() {
        if (typeof this.props.match.params.token != "undefined") {
            apiRequest('register/'+this.props.match.params.token).then(responce =>{
                if (responce.status !== 200) {
                    window.location.href = '/login';
                }
                else{
                    responce.json().then(data => {
                        this.setState({
                            wasInvated: true,
                            invitation: data.invitation,
                            token: this.props.match.params.token
                        })
                    })
                }
            })
        }
    }

    onChange = (e) => {
        var form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        })
    }

    registerClick = () => {
        const { dispatch } = this.props;
        const { wasInvated, token } = this.state;

        let errors = helperValidator.validate(this.state.form, this.state.formValidation);
        this.setState({
            formErrors: {}
        })
        if (Object.keys(errors).length) {
            this.setState({
                formErrors: errors
            })
            return false;
        }
        var toSend = {...this.state.form};
        let params = queryString.parse(this.props.location.search);
        this.setState({
            isLoading:true
        })
        if (params.referral !== undefined) {
            toSend['referral'] = params.referral;
        }
        if (wasInvated) {
            toSend['registration_key'] = token;
        }
        if (toSend['phone_number']) {
            toSend['phone_number'] = "+" + toSend['phone_number'];
        }
        apiRequest('register', 'POST', toSend).then(request => {
            if (request.status === 200) {
                request.json().then(data => {
                    this.setState({
                        registred: true,
                        confirmation_hash: data.confirmation_hash
                    })

                })

            }
            else{
                if (request.status === 401) {
                    this.setState({error: 'Incorrect email or password'})
                }
                else if (request.status === 422) {
                    request.json().then(data => {
                        this.setState({
                            error: data.messages.join("\n")
                        })
                    })
                }
            }

            this.setState({
                isLoading:false
            })

        });
    }

    switchToPremium = () => {
        this.setState({
            registred: true,
            subscriptionStep: false,
        })
    }

    sendEmailVerify = () => {
        const { confirmation_hash } = this.state;

        apiRequest('send-confirmation-email', 'POST', { confirmation_hash: confirmation_hash }).then(response => {
            if (response.status) {
                this.setState({
                    alertMessageWasSend: true
                })
                setTimeout(() => {
                    this.setState({
                        alertMessageWasSend: false
                    })
                }, 6000)
            }
        });
    }

    render() {
        const { error, alertMessageWasSend, formErrors, form, access_token, registred, isLoading, wasInvated, invitation, subscriptionStep, cities , premium_products, waiting_list_cities } = this.state;
        if (subscriptionStep) {
            return  (
                <div className="register-subscription fullscreen">

                    <div className="bg-main-color">
                        <div className="top">
                            <h2>Pricing</h2>
                            <p>Choose the number of cities you want to book with Semper and select your plan</p>
                        </div>
                    </div>
                    <div className="register-subscription-step">
                        <Subscriptions
                            switchToPremium={this.switchToPremium}
                            access_token={access_token}
                            cities={cities}
                            premium_products={premium_products}
                        />
                    </div>
                </div>
            )
        }
        if (registred) {
            return (<div className="fullscreen bg-main-color conform-email-screen">
                <Grid className="fullscreen" justify="center" container alignItems="center" spacing={1}>
                    {alertMessageWasSend && <div className="register-notifications">
                        An email has been sent to you please click on link to confirm your address
                    </div>}
                    <div className="conform-email-screen-content">
                        <img className="logo-on-register-page" src={Logo} alt="Semper"/>
                        {!wasInvated && <>
                            <p>An email has been sent to you please click on link to confirm your address</p>
                            <div style={{textAlign: "center"}}>
                                <Link to="/login"><b>Back to login</b></Link>
                            </div>
                            <Button onClick={this.sendEmailVerify} variant="contained">Send again</Button>
                        </>}
                    </div>
                </Grid>
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </div>)
        }
        return (
            <div className="fullscreen bg-main-color">
                <Grid className="fullscreen" justify="center" container alignItems="center" spacing={1}>
                    <Grid item xs={11} spacing={1}>
                        <br/><br/>
                        <Grid justify="center" container alignItems="center" spacing={1}>
                            <Grid item xs={12} align="center" spacing={1}>
                                <img className="logo-on-register-page" src={Logo} alt="Semper"/>
                                <br/><br/>
                                {!wasInvated && <p>Become member of Semper</p>}
                                {wasInvated && <p>You have been invited to create an account by {invitation.name}</p>}
                            </Grid>
                            <Grid item xs={12} spacing={1}>
                                <TextField
                                    placeholder="First name"
                                    name="firstname"
                                    onChange={this.onChange}
                                    value={form.firstname}
                                    variant="outlined"
                                    fullWidth
                                    error={formErrors['firstname'] !== undefined}
                                    helperText={formErrors['firstname'] !== undefined ? formErrors['firstname'] : ''}
                                />
                                <TextField
                                    placeholder="Last name"
                                    name="lastname"
                                    onChange={this.onChange}
                                    value={form.lastname}
                                    variant="outlined"
                                    fullWidth
                                    error={formErrors['lastname'] !== undefined}
                                    helperText={formErrors['lastname'] !== undefined ? formErrors['lastname'] : ''}
                                />
                                <TextField
                                    placeholder="Email"
                                    name="email"
                                    onChange={this.onChange}
                                    value={form.email}
                                    variant="outlined"
                                    fullWidth
                                    error={formErrors['email'] !== undefined}
                                    helperText={formErrors['email'] !== undefined ? formErrors['email'] : ''}
                                />
                                <TelephoneInput
                                    placeholder="Phone"
                                    name="phone_number"
                                    enableSearch={true}
                                    countryCodeEditable={false}
                                    prefix={"+"}
                                    onChange={
                                        (e) => this.onChange({
                                            target: {
                                                name: 'phone_number',
                                                value: e
                                            }

                                        })
                                    }
                                    value={form.phone_number}
                                    variant="outlined"
                                    fullWidth
                                    helperText={formErrors['phone_number'] !== undefined ? formErrors['phone_number'] : ''}
                                />

                                {!wasInvated && <>
                                    <TextField
                                        placeholder="Instagram name"
                                        name="instagram_name"
                                        onChange={this.onChange}
                                        value={form.instagram_name}
                                        inputProps={{autocomplete: 'off'}}
                                        variant="outlined"
                                        p
                                        fullWidth
                                    />
                                </>}
                                <TextField
                                    placeholder="Password"
                                    name="password"
                                    onChange={this.onChange}
                                    value={form.password}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    error={formErrors['password'] !== undefined}
                                    helperText={formErrors['password'] !== undefined ? formErrors['password'] : ''}
                                />
                                <TextField
                                    placeholder="Password confirmation"
                                    name="password_confirmation"
                                    onChange={this.onChange}
                                    value={form.password_confirmation}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    error={formErrors['password_confirmation'] !== undefined}
                                    helperText={formErrors['password_confirmation'] !== undefined ? formErrors['password_confirmation'] : ''}
                                />
                                <div style={{textAlign: "center"}}><Button disabled={isLoading} onClick={this.registerClick} variant="contained">
                                    {!isLoading && <>Register</>}
                                    {isLoading && <CircularProgress size={29}  />}
                                </Button></div>
                                <br/>
                                <div className="back-to-login" style={{textAlign: "center"}}>
                                    <Link to="/login"><b>Do you have account? Login</b></Link>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar open={!!error}  autoHideDuration={7000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}


export default Register;
