import React from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './screens/login/index';
import Register from './screens/register/index';
import ForgotPassword from './screens/forgotpassword/index';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Loader from '@semper/Loader';
import App from './App';
import { store, history } from '@services/store';
import { Provider } from 'react-redux'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import {browserHistory} from 'react-router';
import { Redirect, withRouter } from 'react-router-dom';
import {HashRouter} from "react-router-dom";
import {apiRequest} from "@services/Request";
import ErrorBoundary from "./ErrorBoundary"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const theme = createMuiTheme({
  // overrides: {
  //   MuiInputBase: {
  //     borderRadius: 12
  //   }
  // }
});

document.addEventListener("deviceready", onDeviceReady, false);

function onDeviceReady() {
    document.getElementsByTagName('html')[0].classList.add(window.device.model)
}

class AppWrapper extends React.Component{

    state = {
        env: 'production'
    }
    componentDidMount() {
        apiRequest('anonymous/app-env', 'POST', null).then(responce => {
            if (responce.status === 200) {
                responce.json().then(data => {
                    if (data.app_env !== 'production') {
                        document.body.classList.add("dev");
                    }
                    if (data.redirect_url && window.location.pathname.indexOf('/page/') !== 0) {
                        window.location.href = data.redirect_url;
                    }
                    if (data.redirect_url) {
                        document.body.classList.add("need_update_app");
                    }
                    this.setState({
                        env: data.app_env
                    })
                })
            }
        })
    }

    render() {
        const { env } = this.state;
        const divClass = (env === 'production') ? 'production' : 'dev';
        return (
            <div className={divClass}>
                <Provider store={store}>
                    <Router history={history}>
                        <ErrorBoundary>
                            <App />
                        </ErrorBoundary>
                    </Router>
                </Provider>
                <ToastContainer position="top-center" />
            </div>

        );
  }
}

export default AppWrapper;
