import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import Button from '@material-ui/core/Button';
import Booking from './booking';
import Dialog from '@material-ui/core/Dialog';
import { apiRequest } from '@services/Request';
import Money from '@images/money.svg'
import RangeTime from '@images/range_time.svg'
import Guest from '@images/guest.svg'
import Share from '@images/share.png'
import Mail from '@images/mail.png'
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SupplierCalendar from '@semper/Calendar/SupplierCalendar';
import { withRouter } from 'react-router-dom'
import { formatTime } from '@semper/FormatTime';
import InviteForm from '../advisor/invite';
import Slide from '@material-ui/core/Slide';
import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { serverUrl } from '@services/Request';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './supplier.css';
const styles = {
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const calendarOption = [
    'lunch',
    'dinner'
]

class Supplier extends React.Component{

    state = {
        openBookingPopup: false,
        sliderIndex: 0,
        selectedOption: 0,
        currency: 'CHF',
        selectedVariant: 0,
        is_allowed_to_book: false,
        selectedVairantObject: {},
        selectedGuests: 0,
        selectedHours: null,
        loading: false,
        openShare: false,
        openInvite: false,
        shareLink: '',
        shareTextCoped: false,
        hourRange: [],
        city_id:0,
        success: '',
        originalOptions: [],
        supplier: {
            name: '',
            images: ['data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=', 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs='],
            address: '',
            direct_phone: '',
            booking_conditions: '',
            general_conditions: '',
            city: 1,
            category_id: 1,
            options: [],
        }
    }

    constructor(props){
        super(props);
        if (window.localStorage.getItem('calendar_option') !== null) {
            this.state['currentOption'] = parseInt(window.localStorage.getItem('calendar_option'))
        }
        else{
            this.state['currentOption'] = 0;
        }
    }

    componentDidMount() {
        this.getSupplierData();
        window.addEventListener('popstate',  event => {
           this.getSupplierData()
        });
    }


    inviteCallback = (message) => {
        this.setState({
            openInvite: false,
            success: message,
        })
    }

    handleClose = () => {
        this.setState({
            success: '',
        })
    }

    getSupplierData = () => {
        if (!this.props.loading) { //TODO this work twice
            this.setState({
                loading:true
            })
            apiRequest('search-supplier-view', 'POST', {supplier_id: this.props.match.params.id, date: this.props.match.params.date}, this.props.auth.api_key).then(responce => {
                if (responce.status === 200) {
                    this.prepareSupplierOption(responce);
                }
            })
        }
    }



    goToLink = (href) => {
        this.props.history.push(href);
        this.getSupplierData();
        this.setState({
            openCalendar: false
        })
    }

    prepareSupplierOption = (responce) => {
        responce.json().then(data => {
            let newSupplier =  data.data;
            let options = newSupplier.options;
            let originalOptions = [...newSupplier.options];
            let relationOptionNames = {};
            let newOptionArrayByOptions = [];
            options.sort((a, b) => {
                if (a.option_name > b.option_name) return 1;
                if (b.option_name > a.option_name) return -1;
                return 0;
            });
            options.map(option => {
                if (typeof relationOptionNames[option.option_name] == "undefined") {
                    relationOptionNames[option.option_name] = Object.keys(relationOptionNames).length;
                }

                if (typeof newOptionArrayByOptions[relationOptionNames[option.option_name]] == "undefined") {
                    newOptionArrayByOptions[relationOptionNames[option.option_name]] = {name: option.option_name, options: []};
                }
                newOptionArrayByOptions[relationOptionNames[option.option_name]]['options'].push(option);
            });
            newSupplier['options'] = newOptionArrayByOptions;

            this.setState({
                originalOptions: originalOptions,
                is_allowed_to_book: data.is_allowed_to_book,
                city_id: data.data.city_id,
                currency: data.data.currency.toUpperCase(),
                supplier: newSupplier,
                loading:false,
            })


            if (window.localStorage.getItem('selected_booking_id') && window.localStorage.getItem('selected_booking_variant_id')) {
                let variantForAction
                originalOptions.forEach(option => {
                    option.options_variants.forEach(variant => {
                        if (variant.id == window.localStorage.getItem('selected_booking_variant_id')) {
                            variantForAction = variant
                        }
                    })
                });
                this.openBookingPopup(
                    parseInt(window.localStorage.getItem('selected_booking_id')),
                    parseInt(window.localStorage.getItem('selected_booking_variant_id')),
                    variantForAction
                )
            }
        })
    }

    invite = () => {
        this.setState({
            openInvite: true,
        })
    }


    inviteForOption = (optionId, variantId, variant) => {
        this.setState({
            selectedVairantObject: variant,
            openInvite: true
        })
    }

    handleCloseInvite = () => {
        this.setState({
            openInvite: false,
            selectedVairantObject: {}
        })
    }

    openBookingPopup = (optionId, selectedVariant, variant) => {
        let hourOptions = [];
        for (let i = variant.start_hour ; i <= variant.end_hour; i += .5) {
            hourOptions.push(i);
        }
        this.setState({
            openBookingPopup: true,
            selectedOption: optionId,
            selectedHours: hourOptions[0],
            hourRange: hourOptions,
            selectedVariant: selectedVariant
        })
    }

    changeVariant = (e) => {
        this.setState({
            selectedVariant: e.target.value,
            selectedGuests: 0
        });
    }

    changeHours = (e) => {
        this.setState({
            selectedHours: e.target.value
        });
    }

    changeNumberGuests = (e) => {
        this.setState({
            selectedGuests: e.target.value
        });
    }
    changeService = (e) => {
        let option = this.state.originalOptions.find(option => {return option.option_id == e.target.value}).options_variants[0];
        let hourOptions = [];
        for (let i = option.start_hour ; i <= option.end_hour; i += .5) {
            hourOptions.push(i);
        }
        this.setState({
            selectedOption: e.target.value,
            selectedGuests: 0,
            selectedHours: hourOptions[0],
            hourRange: hourOptions,
            selectedVariant: option.id
        });
    }

    handleCloseBookingDialog = () => {
        this.setState({
            openBookingPopup: false,
            selectedVariant: 0
        })
    }

    closeCalendar = () => {
        this.setState({
            openCalendar: false
        })
    }
    toHomepage = (e) => {
        if (e.target.value == 20) {
            this.setState({
                openCalendar: true,
                openBookingPopup: false
            })
        }
    }
    
    shareClose = (e) => {
        e.stopPropagation();
        this.setState({
            openShare: false
        })
    }

    clickByShareText = (e) => {
        e.stopPropagation();
        e.target.select();
    }

    copyLink = (e) => {
        e.stopPropagation();
        var copyText = document.getElementById("share-input");
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
        document.execCommand("copy");
        this.setState({
            shareTextCoped: true
        })
    }

    changeTopSlide = (index) => {
        this.setState({
            sliderIndex: index
        })
    }

    changeOption = (option) => {
        window.localStorage.setItem('calendar_option', option);
        this.setState({
            currentOption: option
        })
    }

    render() {
        const { supplier, currency, city_id, is_allowed_to_book,  success, openCalendar,
            openBookingPopup, selectedOption, selectedVariant, loading, selectedGuests, hourRange,
            selectedHours, openInvite, originalOptions, selectedVairantObject,
            openShare, shareTextCoped, shareLink, sliderIndex, currentOption } = this.state;
        const { user } = this.props.auth.user;
        if (loading) {
            return <div style={{display: 'flex',alignItems: "center", justifyContent: "center", height: "100%"}}><CircularProgress /></div>
        }
        return(<div className="supplier-wrapper">
            <SwipeableViews enableMouseEvents onChangeIndex={this.changeTopSlide} axis="x" className="box-shadow-images">
                {supplier.images.map(image => {
                    return (<><img src={serverUrl + '/' + image.path} alt=""/></>)
                })}
            </SwipeableViews>
            {supplier.images.length > 1 && <div className="sliderDots">
                {supplier.images.map((image, index) => {
                    if (index === sliderIndex) {
                        return (<div className="active"></div>)
                    }
                    else return (<div></div>)

                })}
            </div>}
            <div onClick={this.props.history.goBack} className="back-to-search" style={styles.back}>
                <ArrowBackIosIcon />
            </div>
            <p style={{padding:'0 15px', marginBottom: 0, fontSize: 18, fontWeight: 500}}>{supplier.name}</p>
            <span style={{padding:'0 15px', fontSize: 14, color: '#838788'}}>{supplier.city} ({supplier.country})</span>
            <hr style={{height: '0.5px',background: '#797979',border: 0}} />

            <div className="switcher-option">
                <div className={currentOption === 0 ? 'active': ''} onClick={e => this.changeOption(0)}>
                    Lunch
                </div>
                <div className={currentOption === 1 ? 'active': ''} onClick={e => this.changeOption(1)}>
                    Dinner
                </div>
                <div className="background-switcher-option-animation">
                    <div className={currentOption > 0 ? 'active right-side' : 'active'}></div>
                </div>
            </div>

            <div className="changeDateWrapper">
                <span>Options and prices for :</span>
                <FormControl variant="outlined">
                    <Select
                        className="selectOnSupplier"
                        onChange={this.toHomepage}
                        value={0}
                    >
                        <MenuItem value={0}>{this.props.match.params.date.replace(/-/g,"/")}</MenuItem>
                        <MenuItem value={20}>Modify</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <Portal>
                <SupplierCalendar
                    openCalendar={openCalendar}
                    auth={this.props.auth}
                    goToLink={this.goToLink}
                    closeCalendar={this.closeCalendar}
                    supplierName={supplier.name}
                    supplierId={supplier.supplier_id}
                    fromSupplierPage={true}
                    isAnonymous={false}
                />
            </Portal>
            {!!supplier.options.length && <div style={{padding: '24px 15px 0'}}>
                {supplier.options.map(generalOption => {

                    let needShowOptionBlock = false;
                    generalOption.options.forEach(option => {
                        if (
                            (option.option_type !== null && calendarOption[currentOption] === option.option_type.toLowerCase())
                        ) {
                            needShowOptionBlock = true;
                        }
                    });


                    if (needShowOptionBlock) {
                        return (<><div className="optionBlock">
                            <p className="title">{generalOption.name}</p>
                            {generalOption.options.map(option => {
                                if (
                                    option.option_type === null ||
                                    (option.option_type !== null && calendarOption[currentOption] !== option.option_type.toLowerCase())
                                ) {
                                    return <></>
                                }
                                return (option.options_variants.map(variant => {
                                        return (<>
                                            <div className="line">
                                                <span><img src={RangeTime}/><span>{formatTime(variant.start_hour)}<br/>{formatTime(variant.end_hour)}</span></span>
                                                <span><img src={Money}/> <span>{variant.price.replace('.00', '')} {currency} /<br/>guest</span></span>
                                                <span><img src={Guest}/> <span>min<br/>{variant.nb_users_min}&nbsp;guests</span></span>
                                                {user.type === 'guest' && <span><Button onClick={(e) => this.openBookingPopup(option.option_id, variant.id, variant)} className="blackButton small">Book</Button></span>}
                                                {user.type === 'advisor' && <span><Button onClick={(e) => this.inviteForOption(option.option_id, variant.id, variant)} className="blackButton small">Invite</Button></span>}
                                            </div>
                                        </>)
                                    })
                                )
                            })}
                        </div><br/></>)
                    }
                })}
                {<Booking toHomepage={this.toHomepage}
                          {...this.props}
                          currency={currency}
                          date={this.props.match.params.date.replace(/-/g,"/")}
                          booking_conditions={supplier.booking_conditions}
                          changeNumberGuests={this.changeNumberGuests}
                          selectedGuests={selectedGuests}
                          selectedHours={selectedHours}
                          is_allowed_to_book={is_allowed_to_book}
                          changeHours={this.changeHours}
                          changeVariant={this.changeVariant}
                          selectedVariant={selectedVariant}
                          city_id={city_id}
                          changeService={this.changeService}
                          options={originalOptions}
                          hourRange={hourRange}
                          selectedOption={selectedOption}
                          supplier={supplier}
                          openBookingPopup={openBookingPopup}
                          handleCloseBookingDialog={this.handleCloseBookingDialog} />}
            </div>}
            {supplier.options.length === 0 && <p style={{padding: '25px 15px'}}><b>This partner is not available for this date</b></p>}
            <div style={{padding: '24px 15px 0'}}>
                <div className="extraSupplierInfo">
                    {supplier.booking_conditions.replace(/<[^>]*>?/gm, '') && <>
                        <p className="title">Supplier conditions</p>
                        <div dangerouslySetInnerHTML={{__html: supplier.booking_conditions}}></div>
                    </>}
                    {supplier.general_conditions.replace(/<[^>]*>?/gm, '') && <>
                        <p className="title">Description</p>
                        <div dangerouslySetInnerHTML={{__html: supplier.general_conditions}}></div>
                    </>}
                    {supplier.address.replace(/<[^>]*>?/gm, '') && <>
                        <p className="title">Address</p>
                        <div dangerouslySetInnerHTML={{__html: supplier.address}}></div>
                    </>}
                </div>
                <br/><br/><br/>
            </div>
            {user.type === 'advisor' && <div>
                <Dialog TransitionComponent={Transition} keepMounted fullScreen open={openInvite} onClose={this.handleCloseInvite}>
                    <div className="searchHeader">
                        <div></div>
                        <span style={{    position: 'absolute',left: 0, right: 0, textAlign: 'center', width: '80%',margin: 'auto'}}>Invite</span>
                        <CloseIcon onClick={this.handleCloseInvite} />
                    </div>
                    <div>
                        <InviteForm inviteCallback={this.inviteCallback} name={supplier.name} date={this.props.match.params.date} variant={selectedVairantObject} auth={this.props.auth} />
                    </div>
                </Dialog>
            </div>}

            <Snackbar open={!!success}  autoHideDuration={6000} onClose={this.handleClose}>
                <Alert severity="success">
                    {success}
                </Alert>
            </Snackbar>
        </div>)
    }
}

export default withRouter(Supplier)
