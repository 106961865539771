import React from 'react';
import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { connect } from "react-redux"
// import axios from 'axios';
import Loader from '@semper/Loader';
import GuestApp from './GuestApp'
import Page from './screens/page/index'
import InvitedGuestApp from './InvitedGuestApp'
import AuthRedirect from './AuthRedirect';
import { getAuthData } from '@reducers/authentication';
import { userActions } from '@actions/index';
import Auth from './Auth'
import AnonymousBookings from './screens/supplierInterface/bookings/anonymous'
import { apiRequest } from '@services/Request';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { Redirect, withRouter } from 'react-router-dom';
const theme = createMuiTheme({
  // overrides: {
  //   MuiInputBase: {
  //     borderRadius: 12
  //   }
  // }
});

class App extends React.Component{

    state = {
        needLoadedUserInfo: true,
        invitedGuest: false,
        isAuthPage: false
    }

    componentDidMount() {
        const { auth, dispatch } = this.props;
        const pathname = window.location.pathname;
        const isAuthPage = (pathname === '/login' || pathname.indexOf('register') > 0 || pathname === '/forgot_password' || pathname.indexOf('password/reset/') > 0 || pathname.indexOf('/confirmation/') === 0);
        const invitedGuest = pathname.indexOf('booking/') > 0 && pathname.split('/').length > 4;
        if (invitedGuest) {
            this.setState({
                needLoadedUserInfo: false
            })
        }
        else{
            let isSupplierAnonymousPage = pathname.indexOf('/anonymous/bookings/') === 0;
            if (auth.api_key && !auth.loggedIn && !isAuthPage && !isSupplierAnonymousPage) {
                apiRequest('user/details', 'GET', null, auth.api_key).then(request => {
                    if (request.status === 200) {
                        request.json().then(data => {
                            dispatch(userActions.login({
                                access_token: auth.api_key,
                                user: data
                            }));
                        })
                    }
                    else if(request.status === 401){
                        window.location.href = '/login'
                    }

                })
            }
            else{
                this.setState({
                    needLoadedUserInfo: false
                })
            }
        }

    }

    render() {
        const { auth } = this.props;
        const pathname = this.props.location.pathname;
        const isAuthPage = (pathname === '/login' || pathname.indexOf('register') > 0 || pathname === '/forgot_password' || pathname.indexOf('password/reset/') > 0 || pathname.indexOf('/confirmation/') === 0);
        const invitedGuest = pathname.indexOf('booking/') > 0 && pathname.split('/').length > 4 || pathname.indexOf('/invite_token/') >= 0;
        const is3dPaymentRequest = (pathname === '/validate-3dsecure-capture' || pathname === '/validate-3dsecure-guest' ||  pathname === '/validate-3dsecure-subscription' );
        const { needLoadedUserInfo } = this.state;
        // alert(auth.loggedIn);
        if (auth.loading && needLoadedUserInfo && !invitedGuest && !is3dPaymentRequest) {
            return <Loader color={'#F7F7F7'} />
        }

        if (pathname.indexOf('/page/') === 0) {
            return <Page />
        }
        if (pathname.indexOf('/anonymous/bookings/') === 0) {
            return <AnonymousBookings pathname={pathname} />
        }

        // console.log(auth);
        return (
            <>
                {pathname === '/index.html' && <Redirect to="/"/>}
                {isAuthPage && !auth.loggedIn && <Auth isAuthPage={isAuthPage} auth={auth} />}
                {((!isAuthPage && auth.loggedIn) || is3dPaymentRequest) && <GuestApp auth={auth} />}
                {!is3dPaymentRequest && !isAuthPage && !auth.loggedIn && !invitedGuest && <Redirect to="/login"/>  }
                {isAuthPage && !invitedGuest && auth.loggedIn && <Redirect to="/"/>  }
                {invitedGuest && !auth.loggedIn && <InvitedGuestApp />}
            </>
        );
  }
}
function mapStateToProps(state) {
    return {auth: getAuthData(state)}
}

export default connect(mapStateToProps)(withRouter(App));
