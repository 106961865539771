import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import { apiRequest } from '@services/Request';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { userActions } from '@actions/index';
import { helperValidator } from '@helpers/validator';
import { getUser } from '@reducers/authentication'

class Contact extends React.Component{


    state = {
        isLoading: false,
        success: '',
        error: '',
        form: {
            from: '',
            subject: '',
            message: '',
        },
        formValidation: {
            message: 'required',
            subject: 'required',
        },
        formErrors: {}
    }

    onChange = (e) => {
        var form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        })
    }

    handleClose = () => {
        this.setState({
            success: '',
            error: '',
        })
    }

    componentDidMount() {
        const { user } = this.props.auth.user;

        this.setState({
            form: {
                from: user.email,
                subject: '',
                message: '',
            }
        })
    }

    onSave = () => {
        const { api_key } = this.props.auth;
        const { dispatch } = this.props;
        let errors = helperValidator.validate(this.state.form, this.state.formValidation);
        this.setState({
            formErrors: {}
        })
        if (Object.keys(errors).length) {
            this.setState({
                formErrors: errors
            })
            return false;
        }
        this.setState({
            isLoading: true
        });
        apiRequest('user/send-contact-us', 'POST', this.state.form, api_key).then(request => {
            if (request.status === 200) {
                request.json().then(data => {
                    var auth = {...this.props.auth};
                    auth.user.user.firstname = this.state.form.firstname;
                    auth.user.user.lastname = this.state.form.lastname;
                    auth.user.user.phone_number = this.state.form.phone_number;
                    auth.access_token = auth.api_key;
                    this.setState({
                        success: 'Your message has been sent',
                        isLoading: false
                    });
                })

            }
            else{
                if (request.status === 401) {
                    // this.setState({error: 'Incorrect email or password'})
                }
                else if (request.status === 422) {
                    request.json().then(data => {
                        this.setState({
                            error: data.messages.join("\n")
                        })
                    })
                }
            }

            this.setState({
                isLoading:false
            })

        });
    }

    render() {
        const { form, isLoading, success, error, formErrors } = this.state;
        return(
            <>
                <br/>
                <br/>
                <Grid justify="center" container alignItems="center" spacing={1}>
                    <Grid item xs={11} spacing={1}>
                        <Grid justify="center" container alignItems="center" spacing={1}>
                            <Grid item xs={12} spacing={1}>
                                <TextField
                                    label="From"
                                    name="from"
                                    value={form.from}
                                    variant="outlined"
                                    disabled={true}
                                    readonly
                                    fullWidth
                                />
                                <TextField
                                    label="Subject"
                                    name="subject"
                                    onChange={this.onChange}
                                    value={form.subject}
                                    variant="outlined"
                                    fullWidth
                                    error={formErrors['subject'] !== undefined}
                                    helperText={formErrors['subject'] !== undefined ? formErrors['subject'] : ''}
                                />
                                <TextField
                                    label="Message"
                                    name="message"
                                    onChange={this.onChange}
                                    value={form.message}
                                    variant="outlined"
                                    className="multiline-input"
                                    multiline
                                    rows={10}
                                    fullWidth
                                    error={formErrors['message'] !== undefined}
                                    helperText={formErrors['message'] !== undefined ? formErrors['message'] : ''}
                                />
                                <br/><br/>
                                <div style={{textAlign: "center"}}><Button className="blackButton" disabled={isLoading} onClick={this.onSave} variant="contained">
                                    {!isLoading && <>Send</>}
                                    {isLoading && <CircularProgress size={29}  />}
                                </Button></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar open={!!success}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="success">
                        {success}
                    </Alert>
                </Snackbar>
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </>
        )
    }
}


function mapStateToProps(state) {
    return {
        user: getUser(state),
    };
}

export default connect(mapStateToProps)(Contact);
// export default PersonalInformation;
