import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";
import { apiRequest } from '@services/Request';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { userActions } from '@actions/index';
import { getUser } from '@reducers/authentication'
import 'react-phone-input-2/lib/style.css'
import { helperValidator } from '@helpers/validator';
import TelephoneInput from "@semper/TelephoneInput";

class PersonalInformation extends React.Component{


    state = {
        isLoading: false,
        success: '',
        error: '',
        form: {
            firstname: '',
            lastname: '',
            phone_number: '',
            email: '',
        },
        formValidation: {
            firstname: 'required',
            lastname: 'required',
            email: 'email|required',
            phone_number: 'min:5|required',
        },
        formErrors:{},
    }

    onChange = (e) => {
        var form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        })
    }

    componentDidMount() {
        const { auth } = this.props;

        if (typeof auth != "undefined") {
            const { user } = auth.user;
            this.setState({
                form: {
                    firstname: user.firstname,
                    lastname: user.lastname,
                    phone_number: user.phone_number,
                    email: user.email,
                }
            })
        }

    }

    handleClose = () => {
        this.setState({
            success: '',
            error: '',
        })
    }

    onSave = () => {
        const { api_key } = this.props.auth;
        const { dispatch } = this.props;

        let errors = helperValidator.validate(this.state.form, this.state.formValidation);
        this.setState({
            formErrors: {}
        })
        if (Object.keys(errors).length) {
            this.setState({
                formErrors: errors
            })
            return false;
        }
        this.setState({
            isLoading: true
        });
        if (this.state.form['phone_number']) {
            this.state.form['phone_number'] = "+" + this.state.form['phone_number'];
        }
        apiRequest('user/update-profile', 'POST', this.state.form, api_key).then(request => {
            if (request.status === 200) {
                request.json().then(data => {
                    var auth = {...this.props.auth};
                    auth.user.user.firstname = this.state.form.firstname;
                    auth.user.user.lastname = this.state.form.lastname;
                    auth.user.user.phone_number = this.state.form.phone_number;
                    auth.user.user.email = this.state.form.email;
                    auth.access_token = auth.api_key;
                    this.setState({
                        success: 'Your account has been updated',
                        isLoading: false
                    });
                   setTimeout(() => {
                       dispatch(userActions.updateUserInfo(auth));
                   }, 1000)
                })

            }
            else{
                if (request.status === 401) {
                    // this.setState({error: 'Incorrect email or password'})
                }
                else if (request.status === 422) {
                    request.json().then(data => {
                        this.setState({
                            error: data.messages.join("\n")
                        })
                    })
                }
            }

            this.setState({
                isLoading:false
            })

        });
    }

    render() {
        const { form, isLoading, success, error, formErrors, formValidation } = this.state;
        return(
            <>
                <br/>
                <br/>
                <Grid className="fullscreen" justify="center" container alignItems="center" spacing={1}>
                    <Grid item xs={11} spacing={1}>
                        <Grid justify="center" container alignItems="center" spacing={1}>
                            <Grid item xs={12} spacing={1}>
                                <label htmlFor="firstname">Firstname</label>
                                <TextField
                                    name="firstname"
                                    id="firstname"
                                    onChange={this.onChange}
                                    value={form.firstname}
                                    variant="outlined"
                                    fullWidth
                                    error={formErrors['firstname'] !== undefined}
                                    helperText={formErrors['firstname'] !== undefined ? formErrors['firstname'] : ''}
                                />
                                <label htmlFor="lastname">Lastname</label>
                                <TextField
                                    name="lastname"
                                    id="lastname"
                                    onChange={this.onChange}
                                    value={form.lastname}
                                    variant="outlined"
                                    fullWidth
                                    error={formErrors['lastname'] !== undefined}
                                    helperText={formErrors['lastname'] !== undefined ? formErrors['lastname'] : ''}
                                />
                                <label htmlFor="phone_number">Phone number</label>
                                <TelephoneInput
                                    name="phone_number"
                                    id="phone_number"
                                    enableSearch={true}
                                    countryCodeEditable={true}
                                    prefix={"+"}
                                    onChange={
                                        (e) => this.onChange({
                                            target: {
                                                name: 'phone_number',
                                                value: e
                                            }

                                        })
                                    }
                                    value={form.phone_number}
                                    variant="outlined"
                                    fullWidth
                                    helperText={formErrors['phone_number'] !== undefined ? formErrors['phone_number'] : ''}
                                />
                                <label htmlFor="email">Email</label>
                                <TextField
                                    name="email"
                                    id="email"
                                    disabled={true}
                                    onChange={this.onChange}
                                    value={form.email}
                                    variant="outlined"
                                    fullWidth
                                    error={formErrors['email'] !== undefined}
                                    helperText={formErrors['email'] !== undefined ? formErrors['email'] : ''}
                                />
                                <div style={{textAlign: "center"}}><Button className="blackButton" disabled={isLoading} onClick={this.onSave} variant="contained">
                                    {!isLoading && <>Save</>}
                                    {isLoading && <CircularProgress size={29}  />}
                                </Button></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Snackbar open={!!success}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="success">
                        {success}
                    </Alert>
                </Snackbar>
                <Snackbar open={!!error}  autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </>
        )
    }
}


function mapStateToProps(state) {
    return {
        user: getUser(state),
    };
}

export default connect(mapStateToProps)(PersonalInformation);
// export default PersonalInformation;
